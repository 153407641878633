import React, { useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Grid,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { Route, Routes, useLocation } from 'react-router-dom';
import { dashboardViewAtom, navbarColorAtom } from 'src/atoms';
import { CompletedContracts } from 'src/components/dashboard/CompletedContracts';
import { CompletedContractsCondensed } from 'src/components/dashboard/CompletedContractsCondensed';
import { AccountSettings } from '../components/dashboard/AccountSettings';
import { ActionRequired } from '../components/dashboard/ActionRequired';
import { ActionRequiredCondensed } from '../components/dashboard/ActionRequiredCondensed';
import { Greeting } from '../components/dashboard/Greeting';
import { PaymentHistory } from '../components/dashboard/PaymentHistory';
import { SentOffers } from '../components/dashboard/SentOffers';
import { SentOffersCondensed } from '../components/dashboard/SentOffersCondensed';
import { Membership } from '../components/dashboard/Membership';
import { Sidebar } from '../components/dashboard/Sidebar';
import { dashboardTheme } from 'src/theme/theme';
import { AlertModal } from 'src/components/core/AlertModal';
import { ContractTable } from 'src/components/dashboard/ContractTable';
import { archiveContract } from 'src/api';
import { GlobalContracts } from 'src/components/dashboard/GlobalContracts';

export interface DashboardProps extends BoxProps {}
export const Dashboard: React.FC<DashboardProps> = ({ ...props }) => {
  const bg = useColorModeValue(
    'linear-gradient(#FFD3F8, #DCFFF0)',
    'linear-gradient(#654A61, #4A655A)'
  );
  const containerBg = useColorModeValue(
    'rgba(235, 235, 235, 0.45)',
    'rgba(27, 28, 49, 0.5)'
  );

  const [view] = useAtom(dashboardViewAtom);
  const [, setNavbarColor] = useAtom(navbarColorAtom);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    isOpen: isConfirmationOpen,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation
  } = useDisclosure();
  const [contractToArchive, setContractToArchive] = useState<any>();
  const [reloadActiveNegotiations, setReloadActiveNegotiations] =
    useState(true);
  const [reloadSentNegotiations, setReloadSentNegotiations] = useState(true);
  const location = useLocation();
  const hideContract = async () => {
    onCloseConfirmation();

    await archiveContract(contractToArchive!._id);
    setReloadActiveNegotiations(true);
    setReloadSentNegotiations(true);
  };
  useEffect(() => {
    if (view == 'Main') {
      setReloadActiveNegotiations(true);
      setReloadSentNegotiations(true);
    }
  }, [view]);

  useEffect(() => {
    if (location.pathname == '/dashboard') {
      setReloadActiveNegotiations(true);
      setReloadSentNegotiations(true);
    }
  }, [location]);
  return (
    <Box
      background={dashboardTheme.background}
      h={{ base: '100vh', lg: '100vh' }}
      position="relative"
      overflowY={{ base: 'auto', lg: 'hidden' }}
      _before={{
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
      {...props}
    >
      <AlertModal
        isOpen={isConfirmationOpen}
        onAccept={() => hideContract()}
        onDecline={onCloseConfirmation}
        acceptText="Continue"
        declineText="Cancel"
        title="Are you sure you want to delete this contract?"
        text2="Please double check that you want to delete this contract."
        variant="warning"
      />
      <Flex
        h={{ base: 'auto', lg: '100vh' }}
        overflowY={{ base: 'auto', lg: 'hidden' }}
        flexDirection="column"
        position="relative"
      >
        <Flex h="100%" paddingBottom={{ base: '0', lg: '0' }}>
          <Sidebar
            h="100%"
            overflowY="auto"
            display={{ base: 'none', lg: 'initial' }}
          />
          <Flex
            height="100%"
            flex={1}
            flexDirection="column"
            overflowY="auto"
            mx={{ base: '0px', lg: '24px' }}
          >
            <Flex
              flex="1"
              m={{ base: '24px 0 0 0', lg: '24px 0' }}
              overflow="auto"
              borderRadius="5px"
              bg={dashboardTheme.background}
              sx={{ backdropFilter: 'blur(120px)' }}
              flexDirection="column"
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <Box m="24px 20px">
                      {view === 'Main' ? (
                        <>
                          <ActionRequiredCondensed
                            openAlert={onOpenConfirmation}
                            setNegotiationToArchive={setContractToArchive}
                            reloadNegotiations={reloadActiveNegotiations}
                            setReloadNegotiations={setReloadActiveNegotiations}
                          />
                          <Grid
                            templateColumns={{
                              base: 'repeat(1, 1fr)',
                              lg: 'repeat(1, 1fr)'
                            }}
                            // gap={{ lg: '18px' }}
                          >
                            {
                              <ContractTable
                                openAlert={onOpenConfirmation}
                                setNegotiationToArchive={setContractToArchive}
                                reloadNegotiations={reloadSentNegotiations}
                                setReloadNegotiations={
                                  setReloadSentNegotiations
                                }
                              ></ContractTable>
                            }
                            {/* <SentOffersCondensed
                              openAlert={onOpenConfirmation}
                              setNegotiationToArchive={setNegotiationToArchive}
                              reloadNegotiations={reloadSentNegotiations}
                              setReloadNegotiations={setReloadSentNegotiations}
                            />
                            <CompletedContractsCondensed /> */}
                          </Grid>
                        </>
                      ) : view === 'SentOffers' ? (
                        <SentOffers
                          openAlert={onOpenConfirmation}
                          setNegotiationToArchive={setContractToArchive}
                        />
                      ) : view === 'ActionRequired' ? (
                        <ActionRequired
                          openAlert={onOpenConfirmation}
                          setNegotiationToArchive={setContractToArchive}
                        />
                      ) : (
                        <Box></Box>
                      )}
                    </Box>
                  }
                />
                <Route path="/global-contracts" element={<GlobalContracts />} />
                <Route path="/settings" element={<AccountSettings />} />
                <Route path="/payment-history" element={<PaymentHistory />} />
                <Route path="/membership" element={<Membership />} />
              </Routes>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
