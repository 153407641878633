import {
  Button,
  Flex,
  FlexProps,
  Text,
  useColorModeValue,
  Heading,
  Divider,
  Grid,
  GridItem,
  IconButton,
  Avatar,
  Input,
  Box
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserUnfinishedContracts } from '../../api';
import { dashboardViewAtom, userAtom } from '../../atoms';
import { capitalize } from '../../utils/capitalize';
import { Check } from '../icons/Check';
import { Notification } from '../icons/Notification';
import { PlusCircle } from '../icons/PlusCircle';
import { dashboardTheme, loginTheme } from 'src/theme/theme';
import { format } from 'date-fns';

export interface ActionRequiredCondensedProps extends FlexProps {
  openAlert: any;
  setNegotiationToArchive: any;
  reloadNegotiations: boolean;
  setReloadNegotiations: any;
}

export const ActionRequiredCondensed: React.FC<
  ActionRequiredCondensedProps
> = ({
  reloadNegotiations,
  setReloadNegotiations,
  openAlert,
  setNegotiationToArchive,
  ...props
}) => {
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const highlightColor = useColorModeValue('#6B30BA', 'brand.primary');
  const pillBg = useColorModeValue('rgba(4, 5, 24, 0.1)', 'brand.secondary2');
  const bg = useColorModeValue('brand.secondary1', '#1B1C31');
  const createNewBg = useColorModeValue('#DBDBDB', 'brand.secondary2');

  const [user] = useAtom(userAtom);
  const [contracts, setContracts] = useState<any[]>([]);
  const [, setView] = useAtom(dashboardViewAtom);
  const navigate = useNavigate();

  useEffect(() => {
    const getContracts = async () => {
      if (reloadNegotiations) {
        const { data } = await getUserUnfinishedContracts(9, 0);
        setContracts(data);
        setReloadNegotiations(false);
      }
    };

    getContracts();
  }, [reloadNegotiations]);

  const handleOnCreate = async () => {
    navigate('/pre-step-through');
  };

  const handleOnClick = async (pipeline: any) => {
    navigate(`/pipeline/${pipeline._id}`);
  };

  return (
    <>
      <Flex gap="36px" alignItems="center">
        <Heading
          fontWeight="700"
          fontSize={{ base: '28px' }}
          lineHeight="48px"
          color={dashboardTheme.primaryTextColor}
        >
          Your Active Contracts
        </Heading>
        <Button
          variant="transparent"
          h="32px"
          width="129px"
          padding="0 12px 0 12px"
          borderRadius="6px"
          onClick={() => setView('ActionRequired')}
          borderColor={dashboardTheme.buttonBackgroundColor}
          // rightIcon={<Dropdown h="6.67px" w="10.83px" />}
        >
          View all
        </Button>
        <Flex marginLeft={'auto'} gap={6}>
          <Button
            onClick={() => handleOnCreate()}
            color={'black'}
            background={'#ADC3AB'}
            borderColor={'black'}
            borderWidth={'2px'}
            borderStyle={'solid'}
          >
            Start new contract
          </Button>
          {/* <Input
            color={'black'}
            borderColor={'black'}
            placeholder="Search"
            _placeholder={{ color: 'black' }}
          /> */}
        </Flex>
      </Flex>
      <Divider
        mt={{ base: '16px', lg: '20px' }}
        borderColor={dashboardTheme.primaryTextColor}
      />

      <Flex py="24px" gap="16px" flexDir={{ base: 'column', lg: 'row' }}>
        <Button
          display={{ lg: 'none' }}
          onClick={() => handleOnCreate()}
          color={'white'}
        >
          Create new contract
        </Button>
        <Flex
          gap="16px"
          flexDirection={{ base: 'column', lg: 'row' }}
          w="100%"
          overflowX="auto"
        >
          {contracts.map((contract: any) => {
            const pipeline = contract.pipeline;

            let buttonText = '';

            buttonText = 'View Contract';
            return (
              <Flex
                key={pipeline._id}
                background={dashboardTheme.receivedContractBoxBackground}
                borderRadius="5px"
                h={{ lg: '293px' }}
                w={{ base: '100%', lg: '278px' }}
                minW={{ base: '100%', lg: '278px' }}
                padding="16px 18px"
                flexDirection="column"
                justifyContent="space-between"
                gap={{ base: '24px', lg: '0' }}
                borderColor={dashboardTheme.receivedContractBoxBorderColor}
                borderStyle={'solid'}
                borderWidth={1}
                style={{ position: 'relative' }}
              >
                <Grid
                  templateColumns={{
                    base: 'repeat(2, 1fr)',
                    lg: 'repeat(1, 1fr)'
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      position: 'absolute',
                      top: '20px',
                      right: '10px'
                    }}
                    onClick={() => {
                      setNegotiationToArchive(contract);
                      openAlert();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                  </div>
                  <GridItem>
                    <Flex
                      paddingLeft={25}
                      paddingRight={25}
                      marginBottom={'50px'}
                    >
                      <Box
                        display={'inline-block'}
                        background={dashboardTheme.contractTypePillBackground}
                        borderRadius="5px"
                        alignItems="center"
                        justifyContent="center"
                        paddingLeft={25}
                        paddingRight={25}
                        paddingTop={'5px'}
                        paddingBottom={'5px'}
                        marginLeft={'auto'}
                        marginRight={'auto'}
                        textAlign={'center'}
                      >
                        <Text
                          fontSize="10px"
                          fontWeight="700"
                          lineHeight="12px"
                          color={dashboardTheme.contractTypePillTextColor}
                        >
                          {pipeline.priority} Priority
                        </Text>
                      </Box>
                    </Flex>
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="19.2px"
                      color={color}
                      mb={'20px'}
                    >
                      {pipeline.clientName}
                    </Text>
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="19.2px"
                      color={color}
                      mb={'20px'}
                    >
                      {pipeline?.contractType?.name}
                    </Text>
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="19.2px"
                      color={color}
                    >
                      {pipeline.subject}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Flex
                      gap="8px"
                      alignItems={{ lg: 'center' }}
                      justifyContent={{ base: 'flex-end', lg: 'flex-start' }}
                      mt={{ lg: '21px' }}
                    >
                      <Flex></Flex>
                      {/* <Avatar src={otherParty.profileImageUrl} size="sm" />
                      <Flex flexDirection="column">
                        <Text
                          fontSize="12px"
                          fontWeight="400"
                          lineHeight="16px"
                          color={color}
                        >
                          {otherParty.firstName} {otherParty.lastName}
                        </Text>
                        {/* <Text
                          fontSize="10px"
                          fontWeight="700"
                          lineHeight="12px"
                          color={color}
                        >
                          {isInitiator
                            ? capitalize(
                                negotiation.receiverRoleLabel ||
                                  negotiation.receiverRole
                              )
                            : capitalize(
                                negotiation.initiatorRoleLabel ||
                                  negotiation.initiatorRole
                              )}
                        </Text> }
                      </Flex> */}
                    </Flex>
                  </GridItem>
                </Grid>
                <Flex flexDirection="column" gap="8px">
                  <Text
                    fontSize="12px"
                    fontWeight="300"
                    lineHeight="14.4px"
                    mt="8px"
                    color={color}
                    textAlign={'center'}
                  >
                    Sent On:{' '}
                    {format(new Date(pipeline.createdAt), 'dd/MM/yyyy')}
                  </Text>

                  <Button
                    variant="transparent"
                    justifySelf="end"
                    color={dashboardTheme.outlineButtonTextColor}
                    background={'#ADC3AB'}
                    onClick={() => handleOnClick(pipeline)}
                  >
                    Review
                  </Button>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};
