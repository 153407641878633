import api from '../axios/axios';

export const createNegotiation = (data: any) => {
  return api.post('/negotiation', data);
};

export const getNegotiationById = (id: string) => {
  return api.get(`/negotiation/${id}`);
};

export const updateNegotiation = (id: string, data: any) => {
  return api.put(`/negotiation/${id}`, data);
};

export const getUserUnfinishedNegotiationsNoLimit = () => {
  return api.get(`/negotiation/need-current-user-response`);
};

export const getNegotiationRoles = (id: string) => {
  return api.get(`/negotiation/${id}/roles`);
};

export const checkIfCurrentUserIsAttorney = (id: string) => {
  return api.get(`/negotiation/${id}/is-attorney`);
};

export const checkIfCurrentUserIsOverallAttorney = () => {
  return api.get(`/negotiation/is-attorney-overall`);
};
