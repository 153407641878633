import {
  FlexProps,
  Grid,
  GridItem,
  Flex,
  color,
  Button,
  Text,
  Avatar,
  useColorModeValue,
  Box,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  DrawerOverlay,
  Drawer,
  DrawerContent,
  Input,
  useRadio,
  useRadioGroup,
  HStack,
  Select
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import _, { capitalize } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  getAllContracts,
  getAllContractsCount,
  getAllContractsFiltered,
  getContractTypes,
  getOtherPartyUnfinishedContracts,
  getUserUnfinishedContracts,
  getUserUnfinishedContractsCount
} from 'src/api';
import { negotiation } from 'src/assets/data';
import { userAtom } from 'src/atoms';
import { dashboardTheme } from 'src/theme/theme';
import { StageStepper } from '../core/StageStepper';
import { NavLink, useNavigate } from 'react-router-dom';
import { Ellipsis, Menu as MenuIcon } from '../icons/Menu';
import { ListHeader } from './ListHeader';
import { ChartaProse } from '../core/ChartaProse';
import { SubmitHandler, useForm } from 'react-hook-form';
export interface GlobalContractsProps extends FlexProps {}

interface FilterFormInput {
  clientName: string;
  matter: string;
  contractType: string;
  contract: string;
  priority: string;
}

export const GlobalContracts: React.FC<GlobalContractsProps> = ({
  ...props
}) => {
  const [reloadNegotiations, setReloadNegotiations] = useState<boolean>(true);
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const [user] = useAtom(userAtom);
  const [contracts, setContracts] = useState<any[]>([]);
  const [contractTypeOptions, setContractTypeOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const regionOptions = ['ALL', 'US', 'UK', 'Germany'];
  const selectedRegion = useRef('ALL');
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'regions',
    defaultValue: 'ALL',
    onChange: console.log
  });

  const group = getRootProps();
  const RadioCard = (props: any) => {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          color={'black'}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          // boxShadow="md"
          _checked={{
            bg: '#ADC3AB',
            color: 'black',
            borderColor: 'white'
          }}
          _focus={{
            boxShadow: 'outline'
          }}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    );
  };

  const getContractTypeOptions = async () => {
    const response = await getContractTypes();
    const options = response.data.map((contractType: any) => {
      return contractType.name;
    });
    setContractTypeOptions(options);
  };

  const getNumPages = async () => {
    const { data } = await getAllContractsCount();
    setNumPages(data.pages);
  };
  useEffect(() => {
    const getContracts = async () => {
      const { data } = await getAllContracts(9, (page - 1) * 9);
      setContracts(data);
      setReloadNegotiations(false);
    };
    getNumPages().then(() => getContracts());

    getContractTypeOptions().then();
  }, [page]);

  const handleOnClick = async (pipeline: any) => {
    navigate(`/pipeline/${pipeline._id}`);
  };
  const [forceRender, setForceRender] = useState(false);
  const { register, handleSubmit } = useForm<FilterFormInput>();
  const onSubmit: SubmitHandler<FilterFormInput> = (data) => {
    setIsFiltered(true);
    debugger;
    const cleanedData: any = _.omitBy(
      data,
      (value) => value === '' || value == null
    );
    if (selectedRegion.current != 'ALL') {
      cleanedData.selectedRegion = selectedRegion.current;
    }
    getAllContractsFiltered(cleanedData)
      .then((filteredResults) => {
        setContracts(filteredResults.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Box>
      <Flex
        justifyContent={{ base: 'center', lg: 'space-between' }}
        mb={'20px'}
      >
        <Heading
          fontSize="40px"
          fontWeight="400"
          lineHeight="48px"
          color={'black'}
        >
          All Contracts
        </Heading>
        <HStack {...group}>
          {regionOptions.map((value) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard
                key={value}
                {...radio}
                isChecked={selectedRegion.current == value}
                onChange={(e: any) => {
                  selectedRegion.current = e.target.value;
                  setForceRender(!forceRender);
                  handleSubmit(onSubmit)();
                }}
              >
                {value}
              </RadioCard>
            );
          })}
        </HStack>
      </Flex>
      <Grid
        // p="16px"
        mb="5px"
        alignItems="center"
        templateColumns={{
          base: 'repeat(7, 1fr)',
          lg: 'repeat(7, 1fr)'
        }}
        borderColor={'black'}
        borderStyle={'solid'}
        borderWidth={'1px'}
        borderLeftWidth={0}
        borderRightWidth={0}
        backgroundColor={dashboardTheme.receivedContractBoxBackground}
        verticalAlign={'middle'}
        height={'50px'}
      >
        <GridItem>
          <Flex flexDirection="column" gap="8.75px">
            <Text color={'black'} textAlign={'left'} fontWeight={700}>
              Client Name
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column" gap="8.75px">
            <Text color={'black'} textAlign={'center'} fontWeight={700}>
              Matter
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text color={'black'} textAlign={'left'} fontWeight={700}>
              Contract Type
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text
              color={dashboardTheme.receivedContractContractTypeTextColor}
              textAlign={'left'}
              fontWeight={700}
            >
              Contract
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text
              color={dashboardTheme.receivedContractContractTypeTextColor}
              textAlign={'center'}
              fontWeight={700}
            >
              Priority
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDir="column">
            <Text color={color} textAlign={'center'} fontWeight={700}>
              Stage
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          {' '}
          <Flex flexDir="column">
            <Button
              onClick={() => {
                setIsDrawerOpen(true);
              }}
              color={'black'}
              background={'white'}
              borderColor={'black'}
              borderWidth={'2px'}
              borderStyle={'solid'}
              margin={'0px auto'}
              maxW={'200px'}
            >
              Filter
            </Button>
          </Flex>
        </GridItem>
      </Grid>
      {contracts.map((contract) => {
        const pipeline = contract.pipeline;

        return (
          <>
            <Grid
              paddingTop={'16px'}
              paddingBottom={'16px'}
              borderRadius="0px"
              mb="5px"
              key={pipeline._id}
              alignItems="center"
              templateColumns={{
                base: 'repeat(7, 1fr)',
                lg: 'repeat(7, 1fr)'
              }}
              borderColor={'black'}
              borderStyle={'solid'}
              borderWidth={'1px'}
              borderLeftWidth={0}
              borderRightWidth={0}
              backgroundColor={dashboardTheme.receivedContractBoxBackground}
            >
              <GridItem>
                <Flex flexDirection="column" gap="8.75px">
                  <Text color={'black'} textAlign={'left'}>
                    {pipeline.clientName}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex flexDirection="column">
                  <Text color={'black'} textAlign={'center'}>
                    {pipeline.matterName}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                {/* <Avatar src={otherParty.profileImageUrl} size="sm" /> */}
                <Flex flexDirection="column">
                  <Text
                    color={dashboardTheme.receivedContractContractTypeTextColor}
                    textAlign={'left'}
                  >
                    {pipeline.contractType.name}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex flexDirection="column" gap="8.75px">
                  <Text color={'black'} textAlign={'left'}>
                    {pipeline.subject}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                {/* <Avatar src={otherParty.profileImageUrl} size="sm" /> */}
                <Flex flexDirection="column">
                  <Text
                    color={dashboardTheme.receivedContractContractTypeTextColor}
                    textAlign={'center'}
                  >
                    {pipeline.priority}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <StageStepper pipeline={pipeline} />
              </GridItem>
              <GridItem>
                <Flex justifyContent="center" alignItems="center">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Ellipsis height="30px" width="21.3px" />}
                      variant="ghost"
                    />
                    <MenuList zIndex={3}>
                      <MenuItem onClick={() => handleOnClick(pipeline)}>
                        Review
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </GridItem>
            </Grid>
          </>
        );
      })}
      {!isFiltered && (
        <>
          <Box margin={'0px auto'} maxW={200} mt={'100px'}>
            <Flex maxW={200} justifyContent={'space-between'}>
              <svg
                width="14"
                height="22"
                viewBox="0 0 14 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  visibility: page > 1 ? 'visible' : 'hidden'
                }}
                onClick={() => {
                  if (page > 1) {
                    setPage(page - 1);
                  }
                }}
              >
                <path
                  d="M0.0842217 11.0846L13.3849 0.633595L13.5308 21.3472L0.0842217 11.0846Z"
                  fill="black"
                />
              </svg>
              <Text color={'black'}>Page {page}</Text>
              <svg
                width="14"
                height="22"
                viewBox="0 0 14 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  visibility: page < numPages ? 'visible' : 'hidden'
                }}
                onClick={() => {
                  if (page < numPages) {
                    setPage(page + 1);
                  }
                }}
              >
                <path
                  d="M13.9907 10.9582L0.756897 21.4937L0.479031 0.7815L13.9907 10.9582Z"
                  fill="black"
                />
              </svg>
            </Flex>
          </Box>
        </>
      )}

      <Drawer
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        isOpen={isDrawerOpen}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent p="16px" bg="rgba(27, 28, 49, 1)">
          <Heading fontSize="32px" mb={'50px'}>
            Filter
          </Heading>
          <form>
            <Input
              color={'black'}
              _placeholder={{ color: 'black' }}
              borderColor={'black'}
              backgroundColor={'white'}
              placeholder={'Client Name'}
              {...register('clientName')}
              mb={'50px'}
            />
            <Input
              color={'black'}
              _placeholder={{ color: 'black' }}
              borderColor={'black'}
              backgroundColor={'white'}
              placeholder={'Client Matter'}
              {...register('matter')}
              mb={'50px'}
            />
            <Select
              iconColor="black"
              placeholder={'Contract Type'}
              {...register('contractType')}
              color={'black'}
              _placeholder={{ color: 'black' }}
              borderColor={'black'}
              backgroundColor={'white'}
              mb={'50px'}
            >
              {contractTypeOptions.map((opt: any) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </Select>
            {/* <Input
              color={'black'}
              _placeholder={{ color: 'black' }}
              borderColor={'black'}
              backgroundColor={'white'}
              placeholder={'Contract Type'}
              {...register('contractType')}
              mb={'50px'}
            /> */}
            <Input
              color={'black'}
              _placeholder={{ color: 'black' }}
              borderColor={'black'}
              backgroundColor={'white'}
              placeholder={'Contract'}
              {...register('contract')}
              mb={'50px'}
            />
            <Select
              iconColor="black"
              placeholder={'Priority'}
              {...register('priority')}
              color={'black'}
              _placeholder={{ color: 'black' }}
              borderColor={'black'}
              backgroundColor={'white'}
              mb={'50px'}
            >
              {['High', 'Medium', 'Low'].map((opt: any) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </Select>
            <Button
              type="button"
              backgroundColor={'#7BFF66'}
              color={'black'}
              onClick={handleSubmit(onSubmit)}
            >
              {' '}
              Filter
            </Button>
          </form>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
