import {
  FlexProps,
  Grid,
  GridItem,
  Flex,
  color,
  Button,
  Text,
  Avatar,
  useColorModeValue,
  Box,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  getOtherPartyUnfinishedContracts,
  getOtherPartyUnfinishedContractsCount,
  getUserUnfinishedContracts,
  getUserUnfinishedContractsCount
} from 'src/api';
import { negotiation } from 'src/assets/data';
import { userAtom } from 'src/atoms';
import { dashboardTheme } from 'src/theme/theme';
import { StageStepper } from '../core/StageStepper';
import { NavLink, useNavigate } from 'react-router-dom';
import { Ellipsis, Menu as MenuIcon } from '../icons/Menu';
import { ListHeader } from './ListHeader';
export interface ContractTableProps extends FlexProps {
  openAlert: any;
  setNegotiationToArchive: any;
}

export const SentOffers: React.FC<ContractTableProps> = ({
  openAlert,
  setNegotiationToArchive,
  ...props
}) => {
  const [reloadNegotiations, setReloadNegotiations] = useState<boolean>(true);
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const [user] = useAtom(userAtom);
  const [contracts, setContracts] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const navigate = useNavigate();

  const getNumPages = async () => {
    const { data } = await getOtherPartyUnfinishedContractsCount();
    setNumPages(data.pages);
  };
  useEffect(() => {
    const getContracts = async () => {
      const { data } = await getOtherPartyUnfinishedContracts(
        9,
        (page - 1) * 9
      );
      setContracts(data);
      setReloadNegotiations(false);
    };
    getNumPages().then(() => getContracts());
    getContracts();
  }, [page]);

  const handleOnClick = async (pipeline: any) => {
    navigate(`/pipeline/${pipeline._id}`);
  };

  return (
    <>
      <ListHeader
        title="All Approved Contracts"
        mb="16px"
        color={dashboardTheme.primaryTextColor}
        setReloadNegotiations={setReloadNegotiations}
      />
      <Grid
        // p="16px"
        mb="5px"
        alignItems="center"
        templateColumns={{
          base: 'repeat(7, 1fr)',
          lg: 'repeat(7, 1fr)'
        }}
        borderColor={'black'}
        borderStyle={'solid'}
        borderWidth={'1px'}
        borderLeftWidth={0}
        borderRightWidth={0}
        backgroundColor={dashboardTheme.receivedContractBoxBackground}
        verticalAlign={'middle'}
        height={'50px'}
      >
        <GridItem>
          <Flex flexDirection="column" gap="8.75px">
            <Text color={'black'} textAlign={'left'} fontWeight={700}>
              Client Name
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column" gap="8.75px">
            <Text color={'black'} textAlign={'center'} fontWeight={700}>
              Matter
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text color={'black'} textAlign={'left'} fontWeight={700}>
              Contract Type
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text
              color={dashboardTheme.receivedContractContractTypeTextColor}
              textAlign={'left'}
              fontWeight={700}
            >
              Contract
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text
              color={dashboardTheme.receivedContractContractTypeTextColor}
              textAlign={'center'}
              fontWeight={700}
            >
              Priority
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDir="column">
            <Text color={color} textAlign={'center'} fontWeight={700}>
              Stage
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          {' '}
          <Flex flexDir="column"></Flex>
        </GridItem>
      </Grid>
      {contracts.map((contract) => {
        const pipeline = contract.pipeline;

        return (
          <>
            <Grid
              paddingTop={'16px'}
              paddingBottom={'16px'}
              borderRadius="0px"
              mb="5px"
              key={pipeline._id}
              alignItems="center"
              templateColumns={{
                base: 'repeat(7, 1fr)',
                lg: 'repeat(7, 1fr)'
              }}
              borderColor={'black'}
              borderStyle={'solid'}
              borderWidth={'1px'}
              borderLeftWidth={0}
              borderRightWidth={0}
              backgroundColor={dashboardTheme.receivedContractBoxBackground}
            >
              <GridItem>
                <Flex flexDirection="column" gap="8.75px">
                  <Text color={'black'} textAlign={'left'}>
                    {pipeline.clientName}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex flexDirection="column">
                  <Text color={'black'} textAlign={'center'}>
                    {pipeline.matterName}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                {/* <Avatar src={otherParty.profileImageUrl} size="sm" /> */}
                <Flex flexDirection="column">
                  <Text
                    color={dashboardTheme.receivedContractContractTypeTextColor}
                    textAlign={'left'}
                  >
                    {pipeline.contractType.name}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex flexDirection="column" gap="8.75px">
                  <Text color={'black'} textAlign={'left'}>
                    {pipeline.subject}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                {/* <Avatar src={otherParty.profileImageUrl} size="sm" /> */}
                <Flex flexDirection="column">
                  <Text
                    color={dashboardTheme.receivedContractContractTypeTextColor}
                    textAlign={'center'}
                  >
                    {pipeline.priority}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <StageStepper pipeline={pipeline} />
              </GridItem>
              <GridItem>
                <Flex justifyContent="center" alignItems="center">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Ellipsis height="30px" width="21.3px" />}
                      variant="ghost"
                    />
                    <MenuList zIndex={3}>
                      <MenuItem onClick={() => handleOnClick(pipeline)}>
                        Review
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </GridItem>
            </Grid>
          </>
        );
      })}
      <Box margin={'0px auto'} maxW={200} mt={'100px'}>
        <Flex maxW={200} justifyContent={'space-between'}>
          <svg
            width="14"
            height="22"
            viewBox="0 0 14 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              visibility: page > 1 ? 'visible' : 'hidden'
            }}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            <path
              d="M0.0842217 11.0846L13.3849 0.633595L13.5308 21.3472L0.0842217 11.0846Z"
              fill="black"
            />
          </svg>
          <Text color={'black'}>Page {page}</Text>
          <svg
            width="14"
            height="22"
            viewBox="0 0 14 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              visibility: page < numPages ? 'visible' : 'hidden'
            }}
            onClick={() => {
              if (page < numPages) {
                setPage(page + 1);
              }
            }}
          >
            <path
              d="M13.9907 10.9582L0.756897 21.4937L0.479031 0.7815L13.9907 10.9582Z"
              fill="black"
            />
          </svg>
        </Flex>
      </Box>
    </>
  );
};
