import React from 'react';
import { Flex, FlexProps, Heading, Divider } from '@chakra-ui/react';
import { ChartaBack } from '../core/ChartaBack';
import { useAtom } from 'jotai';
import { dashboardViewAtom } from 'src/atoms';
import { useNavigate } from 'react-router-dom';

export interface ListHeaderProps extends FlexProps {
  setReloadNegotiations?: any;
  title: string;
}

export const ListHeader: React.FC<ListHeaderProps> = ({
  setReloadNegotiations,
  title,
  ...props
}) => {
  const navigate = useNavigate();
  const [, setView] = useAtom(dashboardViewAtom);
  const handleOnBack = () => {
    setView('Main');
    setReloadNegotiations(true);
  };

  return (
    <Flex flexDir="column" alignItems="flex-start" {...props}>
      <ChartaBack label="Back" pl="0" onClick={() => setView('Main')} />
      <Heading
        mt="18px"
        fontWeight="700"
        fontSize={{ base: '28px' }}
        lineHeight="48px"
      >
        {title}
      </Heading>
      <Divider />
    </Flex>
  );
};
