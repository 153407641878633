import { useEffect, useRef, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  checkIfCurrentUserIsAttorney,
  createContract,
  getNegotiationById,
  getNegotiationRoles,
  updateNegotiation
} from 'src/api';
import { navbarColorAtom, userAtom } from 'src/atoms';
import { AlertModal } from 'src/components/core/AlertModal';
import { LoadScreen } from 'src/components/core/LoadScreen';
import { Answer, Pipeline as IPipeline } from 'src/interfaces';
import { NegotiationPanel } from '../components/negotiation/NegotiationPanel';
import { ContractType } from '../interfaces';
import { parseConditions } from 'src/utils/parseConditions';
import { parseAnswers } from 'src/utils/parseAnswers';
import _ from 'lodash';
import { negotiationPanelTheme } from 'src/theme/theme';
import {
  getPipelineById,
  moveToNextStage,
  moveToPreviousStage,
  updatePipeline
} from 'src/api/pipeline';
import { PipelinePanel } from 'src/components/pipeline/PipelinePanel';
export const Pipeline = () => {
  const [pipeline, setPipeline] = useState<IPipeline | null>();
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenError,
    onClose: onCloseError,
    onOpen: onOpenError
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGeneratingContract, setIsGeneratingContract] =
    useState<boolean>(false);

  const [params] = useSearchParams();
  const [isFirstCounter, setIsFirstCounter] = useState<boolean>(false);
  const hasCounterParam = !!params.get('first-counter');
  const roleRetrieved: any = useRef(null);
  const [firstCounterAnswers, setFirstCounterAnswers] = useState<any[]>([]);
  const [isAttorney, setIsAttorney] = useState(false);

  const getAttorneyState = async () => {
    if (pipeline != null) {
      const attorneyResponse = await checkIfCurrentUserIsAttorney(pipeline._id);
      if (attorneyResponse?.data?.isAttorney == true) {
        setIsAttorney(true);
      } else {
        setIsAttorney(false);
      }
    }
  };

  // useEffect(() => {
  //   getAttorneyState();
  // }, [pipeline]);

  // const [user] = useAtom(userAtom);
  // //Check if this is the first response
  // useEffect(() => {
  //   const setRole = async () => {
  //     if (
  //       hasCounterParam &&
  //       pipeline &&
  //       pipeline.receiverResponses.length === 0 &&
  //       roleRetrieved.current === null
  //     ) {
  //       const { data } = await getNegotiationRoles(pipeline._id);

  //       /**
  //        * Set answers to hidden questions
  //        */
  //       const answers = [
  //         {
  //           stepId: 'role',
  //           subStepId: 'role',
  //           questionId: 'role',
  //           fieldId: 'role',
  //           value: data.receiverRole
  //         }
  //       ];

  //       const hiddenQuestions = [];
  //       const conditions = (pipeline?.contractType as ContractType)?.conditions;
  //       for (const step of (pipeline?.contractType as ContractType)?.form
  //         ?.steps) {
  //         for (const subStep of step?.subSteps) {
  //           const subStepCondition = conditions.subSteps?.find(
  //             (c: any) => c[subStep.id] !== undefined
  //           );
  //           if (
  //             subStepCondition != null &&
  //             !parseConditions(subStepCondition, parseAnswers(answers))
  //           ) {
  //             continue;
  //           }
  //           for (const question of subStep.questions) {
  //             const questionCondition = conditions.questions?.find(
  //               (c: any) => c[question.id] !== undefined
  //             );
  //             if (!question.hidden) {
  //               continue;
  //             }
  //             const conditionForBeingHidden =
  //               question?.pathWithDefaultValue?.condition;
  //             if (
  //               conditionForBeingHidden != null &&
  //               !parseConditions(conditionForBeingHidden, parseAnswers(answers))
  //             ) {
  //               continue;
  //             }
  //             if (
  //               questionCondition != null &&
  //               !parseConditions(questionCondition, parseAnswers(answers))
  //             ) {
  //               continue;
  //             }
  //             hiddenQuestions.push({
  //               ...question,
  //               stepId: step.id,
  //               subStepId: subStep.id
  //             });
  //           }
  //         }
  //       }

  //       for (const hiddenQuestion of hiddenQuestions) {
  //         const value = _.get(
  //           { user },
  //           hiddenQuestion?.pathWithDefaultValue.value
  //         );
  //         answers.push({
  //           stepId: hiddenQuestion.stepId,
  //           subStepId: hiddenQuestion.subStepId,
  //           fieldId: hiddenQuestion.field.id,
  //           questionId: hiddenQuestion.id,
  //           value
  //         });
  //       }

  //       setPipeline({
  //         ...pipeline,
  //         latestReceiverResponses: {
  //           date: new Date().toString(),
  //           answers
  //         },
  //         receiverResponses: [
  //           {
  //             date: new Date().toString(),
  //             answers
  //           }
  //         ]
  //       });
  //       setIsFirstCounter(true);
  //       setFirstCounterAnswers(answers);
  //       roleRetrieved.current = data.receiverRole;
  //     }
  //   };

  //   setRole();
  // }, [hasCounterParam, pipeline, user]);

  useEffect(() => {
    if (location.state?.showSentAlert) {
      onOpen();
    }
  }, [location.state?.showSentAlert, pipeline, onOpen]);

  useEffect(() => {
    const getPipeline = async () => {
      const { data } = await getPipelineById(id!);

      setPipeline({ ...data });
    };

    try {
      setIsLoading(true);
      getPipeline();
    } catch (error) {
      onOpenError();
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [id, onOpenError, setPipeline]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitAnswers = async (
    answers: Answer[],
    showSuccessModal = true
  ) => {
    debugger;
    let cleanAnswers: Answer[] = answers.map(
      ({ stepId, subStepId, questionId, fieldId, value, comment }) => ({
        stepId,
        subStepId,
        questionId,
        fieldId,
        value,
        ...(comment != null && {
          comment
        })
      })
    ); //This is because Luke's API doesn't check that form of the answer object - need to fix that

    if (isFirstCounter) {
      cleanAnswers = [...cleanAnswers, ...firstCounterAnswers];
    }

    try {
      setIsLoading(showSuccessModal);
      const { data } = await updatePipeline(pipeline!._id, {
        answers: cleanAnswers
      });
      setPipeline(data);
      if (isAttorney) {
        handleViewContract();
      } else if (showSuccessModal) {
        onOpen();
      }
    } catch (err) {
      setIsLoading(false);
      onOpenError();
    } finally {
      setIsLoading(false);
    }
  };

  const moveForward = async () => {
    try {
      // setIsLoading(true);
      const { data } = await moveToNextStage(pipeline!._id);
      setPipeline(data);
    } catch (err) {
      setIsLoading(false);
      onOpenError();
    } finally {
      setIsLoading(false);
    }
  };

  const moveBackward = async () => {
    try {
      setIsLoading(true);
      const { data } = await moveToPreviousStage(pipeline!._id);
      setPipeline(data);
    } catch (err) {
      setIsLoading(false);
      onOpenError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewContract = async () => {
    try {
      setIsGeneratingContract(true);
      const { data } = await createContract(pipeline!._id);
      setIsGeneratingContract(false);
      navigate(`/contract/${data.contract._id}`);
    } catch (err) {
      setIsGeneratingContract(false);
      onOpenError();
    } finally {
      setIsGeneratingContract(false);
    }
  };

  if (isGeneratingContract) {
    return <LoadScreen label="Generating contract..." />;
  }

  if (isLoading) {
    return <LoadScreen label="Loading..." />;
  }

  if (!pipeline) {
    return <></>;
  }

  return (
    <Flex
      flexDirection="column"
      h="100vh"
      // bgImage="url(/studio.jpg)"
      // bgPosition="center"
      // bgSize="cover"
      bg={negotiationPanelTheme.background}
      overflowY={{ lg: 'hidden' }}
    >
      <Flex
        h="100%"
        alignItems="start"
        justifyContent="space-between"
        overflowY={{ base: 'auto', lg: 'hidden' }}
      >
        <PipelinePanel
          pipeline={pipeline}
          onSubmitAnswers={handleSubmitAnswers}
          onMoveForward={moveForward}
          onMoveBackward={moveBackward}
          onViewContract={handleViewContract}
        />
      </Flex>
      <AlertModal
        isOpen={isOpen}
        onAccept={() => {
          try {
            location.state.showSentAlert = false;
          } catch (er) {}
          onClose();
        }}
        acceptText="Continue"
        title={
          location.state?.showSentAlert
            ? 'Step through has been completed. You can now review your terms and edit them before sending them out for approval.'
            : 'Progress has been saved'
        }
        variant="success"
      />
      <AlertModal
        isOpen={isOpenError}
        onAccept={onCloseError}
        variant="warning"
        title="Something went wrong!"
        acceptText="OK"
      />
    </Flex>
  );
};
